<template>
    <Logo color="purple" class="logo-fix" />
    <div class="row h-full">
        <div class="left" v-if="sidebar">
            <Info v-model="drawer" :file="info" v-if="drawer" />
            <div v-if="step == 0">
                <img src="/images/undraw_security_o890.svg" class="art">
                <h1 class="title">{{ $t('title.is_secure') }}</h1>
                <ul>
                    <li>{{ $t('desc.encrypt_rsa') }}</li>
                    <li>{{ $t('desc.decrypt_in_browser') }} (<a @click="openInfo('encrypt')">{{ $t('desc.prove_it') }}</a>)</li>
                    <li>{{ $t('desc.no_data_sent') }} (<a @click="openInfo('no-data')">{{ $t('desc.prove_it') }}</a>)</li>
                     <li>{{ $t('desc.not_external_link') }} (<a @click="openInfo('external-script')">{{ $t('desc.prove_it') }}</a>)</li>
                </ul>
            </div>
            <div v-else>
                <img src="/images/undraw_message_sent_1030.svg" class="art">
                <h1 class="title">{{ $t('title.is_decrypt') }}</h1>
                <ul>
                    <li>{{ $t('desc.only_you') }}</li>
                    <li>{{ $t('desc.is_not_sent') }} (<a href="#">{{ $t('desc.prove_it') }}</a>)</li>
                </ul>
            </div>
            <div class="center mt-10 mobile">
                <button class="btn" @click="sidebar = false">{{ $t('close') }}</button>
            </div>
        </div>
        <div class="right" :class="{block: step==1}">
            <navbar @command="selectMenu" v-if="step == 1">
                <el-dropdown-menu>
                    <el-dropdown-item icon="el-icon-download" command="pubkey">{{ $t('button.download_your_public_key') }}</el-dropdown-item>
                    <!--<el-dropdown-item icon="el-icon-download" command="pubkey-ssh">Télécharger votre clé publique SSH</el-dropdown-item>-->
                    <el-dropdown-item icon="el-icon-download" command="privkey">{{ $t('button.download_your_private_key') }}</el-dropdown-item>
                    <!--<el-dropdown-item icon="el-icon-download" command="privkey-ssh">Télécharger votre clé privé SSH</el-dropdown-item>-->
                </el-dropdown-menu>
            </navbar>
            <div v-if="!loading">
                    <div class="crypt" v-if="step == 0">
                        <h1 class="title mobile">{{ $t('title.decrypt') }} <a class="min" @click="sidebar = true">({{ $t('title.is_secure') }})</a></h1>
                        <form @submit.prevent="decrypt">
                            <input type="password" v-model="password" :placeholder="$t('desc.set_pass')" class="input">
                            <div v-if="error" class="mt-10"><el-alert
                                :title="error"
                                type="error"
                                show-icon>
                            </el-alert></div>
                            
                            <button class="btn mt-10" :disabled="password.length == 0">
                                {{ $t('decrypt') }}
                            </button>
                        </form>
                </div>
                <div v-else>
                    <div class="content" v-html="content"></div>
                </div>
            </div>
            <div v-else class="center">
                <Loader class="mr-10" />
                <p>{{ $t('loading.decrypting') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../components/Navbar'
import { downloadKeys } from '../services/download-keys' 
import NodeRSA from 'node-rsa'
import CryptoJS from 'crypto-js'
import DOMPurify from 'dompurify'
import axios from 'axios'
import { API_URL } from '../config'
import Loader from '../components/Loader'
import Info from '../components/Info'
import Logo from '../components/Logo'
import InfoMixin from '../mixins/info'

export default {
    mixins: [InfoMixin],
    data() {
        return {
            id: '',
            content: '',
            step: 0,
            password: '',
            crypt: '',
            loading: false,
            error: '',
            key: ''
        }
    },
    mounted() {
        this.id = this.$route.params.id
        this.crypt = location.hash.replace(/^#/, '')
    },
    methods: {
        async decrypt() {
            try {
                this.loading = true

                const data = (await axios.post(API_URL + '/auth/' + this.id, {
                    hash: CryptoJS.SHA256(this.password  + this.id).toString()
                })).data

                this.encryptPrivateKey = data.privkey

                this.key = new NodeRSA()
                const bytes = CryptoJS.AES.decrypt(this.encryptPrivateKey, this.password)
                
                this.password = '' // remove password of memory

                const privateKey = bytes.toString(CryptoJS.enc.Utf8)
                this.key.importKey(privateKey, 'private')
                const text = this.key.decrypt(this.crypt.toString()).toString()
                this.content = DOMPurify.sanitize(text, {ALLOWED_TAGS: ['p', 'b', 'i', 'h1']})
                this.content = this.content.replace(/\n/g, '<br>')
                this.step = 1
                this.loading = false
            }
            catch (err) {
                const status = err?.response?.status
                if (status == 401) {
                    this.error = this.$t('error.pass_false')
                }
                else {
                    this.error = err.message
                }
                this.loading = false
            }
        },
        async selectMenu(name) {
            if (name == 'privkey') {
                await this.$confirm(this.$t('warning_download_private_key'), this.$t('warning'), {
                    confirmButtonText: this.$t('ok'),
                    cancelButtonText: this.$t('cancel'),
                    type: 'warning'
                })
            }
            downloadKeys(name, this.id, this.key)
        }
    },
    components: {
        Loader,
        Navbar,
        Info,
        Logo
    }
}
</script>

<style scoped>
.content {
   padding: 100px;
}
</style>