import { createRouter, createWebHistory } from 'vue-router'
import Encrypt from './pages/Encrypt'
import Decrypt from './pages/Decrypt'
import CreateKeys from './pages/CreateKeys'
import Home from './pages/Home'

const routes = [
  {
    path: "/",
    name: 'home',
    component: Home,
  },
  {
    path: "/decrypt/:id",
    name: 'decrypt',
    component: Decrypt,
  },
  {
    path: "/encrypt/:id",
    name: 'encrypt',
    component: Encrypt,
  },
  {
    path: "/create",
    name: 'create',
    component: CreateKeys,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router