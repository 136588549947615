<template>
  <div class="logo" :class="color">
      <img :src="img" class="mr-10"> <span>Cryptery</span>
  </div>
</template>

<script>
export default {
    props: {
        color: {
            default: 'white'
        }
    },
    computed: {
        img() {
            return `/images/${this.color == 'white' ? 'lock' : 'lock-purple'}.png`
        }
    }
}
</script>

<style scoped>
.logo {
    display: flex;
    padding: 20px;
}

span {
    
    font-size: 30px;
}

img {
    width: 35px;
}

.white span {
    color: white;
}

.purple span {
    color: #231279;
}
</style>