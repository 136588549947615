<template>
  <div class="center h-full">
      <div class="error-content">
            <div class="img"><img src="/images/undraw_void_3ggu.svg" class="art"></div>
            <h1>{{ msg }}</h1>
      </div>
  </div>
</template>

<script>
export default {
    props: ['msg']
}
</script>

<style scoped>
h1 {
    text-align: center;
    margin-top: 80px;
}

.img {
    text-align: center;
}

.error-content {
    width: 400px;
}
</style>