import { IS_MOBILE } from '../config'

const InfoMixin = {
   data() {
       return {
            drawer: false,
            info: '',
            sidebar: !IS_MOBILE
       }
   },
   methods: {
        openInfo(info) {
            this.info = info
            this.drawer = true
        }
   }
}

export default InfoMixin