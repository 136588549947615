<template>
     <el-drawer
        v-model="isOpen"
        direction="ltr"
        :size="size">
        <div class="info-content" v-html="html"></div>
    </el-drawer>
</template>

<script>
import axios from 'axios'
import marked from 'marked'
import { IS_MOBILE } from '../config'

export default {
    props: ['file', 'open'],
    data() {
        return {
            isOpen: false,
            html: '',
            size: IS_MOBILE ? '100%' : '50%'
        }
    },
    model: {
        prop: 'open',
        event: 'input'
    },
    async mounted() {
        const { data: md } = await axios.get('/md/fr/' + this.file + '.md')
        this.html = marked(md)
    },
    watch: {
        isOpen(val) {
            this.prop = val
        }   
    }
}
</script>

<style scoped>
.info-content {
    padding: 30px;
    padding-top: 0;
}
</style>