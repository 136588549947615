<template>
    <Logo color="purple" class="logo-fix" />
    <Error :msg="error" v-if="error" />
    <div v-else class="h-full">
        <div class="row h-full" v-if="!loading">
            <div class="left" v-if="sidebar">
                <Info v-model="drawer" :file="info" v-if="drawer" />
                <div v-if="step == 0">
                    <img src="/images/undraw_security_o890.svg" class="art">
                    <h1 class="title">{{ $t('title.is_secure') }}</h1>
                    <ul>
                        <li>{{ $t('desc.encrypt_rsa') }}</li>
                        <li>{{ $t('desc.decrypt_in_browser') }} (<a @click="openInfo('encrypt')">{{ $t('desc.prove_it') }}</a>)</li>
                        <li>{{ $t('desc.no_data_sent') }} (<a @click="openInfo('no-data')">{{ $t('desc.prove_it') }}</a>)</li>
                         <li>{{ $t('desc.not_external_link') }} (<a @click="openInfo('external-script')">{{ $t('desc.prove_it') }}</a>)</li>
                    </ul>
                </div>
                <div v-else>
                    <img src="/images/undraw_Security_on_ff2u.svg" class="art">
                    <h1 class="title">{{ $t('title.is_encrypt') }}</h1>
                    <ul>
                        <li>{{ $t('desc.send_to', { name }) }}</li>
                        <li>{{ $t('desc.only_can_decrypt', { name }) }}</li>
                    </ul>
                </div>
                <div class="center mt-10 mobile">
                    <button class="btn" @click="sidebar = false">{{ $t('close') }}</button>
                </div>
            </div>
            <div class="right">
                <navbar @command="selectMenu">
                    <el-dropdown-menu>
                        <el-dropdown-item icon="el-icon-download" command="pubkey">{{ $t('button.download_public_key_of', { name }) }}</el-dropdown-item>
                       <!-- <el-dropdown-item icon="el-icon-download" command="pubkey-ssh">Télécharger la clé publique SSH de {{ name }}</el-dropdown-item>-->
                    </el-dropdown-menu>
                </navbar>
                <div v-if="step == 0">
                    <h2 class="subtitle mt-50">{{ $t('title.encrypt_for', { name }) }}<a class="min mobile" @click="sidebar = true">({{ $t('title.is_secure') }})</a></h2>
                    <form @submit.prevent="encrypt">
                        <textarea class="textarea" v-model="content" placeholder="Tapez votre message ici"></textarea>
                        <button class="btn mt-10">{{ $t('encrypt') }}</button>
                    </form>
                </div>
                <div v-else>
                    <div class="crypt">
                        <input type="text" v-model="crypt" readonly id="crypt" class="input">
                        <button class="btn btn-clip mt-10 mr-10" data-clipboard-target="#crypt">
                            {{ $t('copy') }}
                        </button>
                        <a class="btn secondary" @click="back">{{ $t('back') }} </a>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="flex center h-full mr-10">
            <Loader />
            <p>{{ $t('loading.public_key') }}</p>
        </div>
    </div>
</template>

<script>
import Navbar from '../components/Navbar'
import { downloadKeys } from '../services/download-keys' 
import NodeRSA from 'node-rsa'
import ClipboardJS from 'clipboard'
import Loader from '../components/Loader'
import axios from 'axios'
import { API_URL } from '../config'
import Error from './Error'
import Info from '../components/Info'
import Logo from '../components/Logo'
import InfoMixin from '../mixins/info'

export default {
    mixins: [InfoMixin],
    data() {
        return {
            content: '',
            step: 0,
            crypt: '',
            id: '',
            loading: true,
            name: '',
            key: null,
            error: ''
        }
    },
    async mounted() {
        try {
            this.id = this.$route.params.id
            const data = (await axios.get(API_URL + '/key/' + this.id)).data
            this.publicKey = data.pubKey
            this.name = data.name
            new ClipboardJS('.btn-clip')
            this.key = new NodeRSA()
            this.key.importKey(this.publicKey, 'public')
            this.loading = false
        }
        catch (err) {
            const status = err?.response?.status
            if (status == 404) {
                this.error = this.$t('error.public_key_not_found')
            }
            else {
                this.error = err.message
            }
            this.loading = false
        }
    },
    methods: {
        encrypt() {
            this.crypt = `${API_URL}/decrypt/${this.id}#` + this.key.encrypt(this.content.trim()).toString('base64')
            this.step = 1
        },
        back() {
            this.step = 0
        },
        selectMenu(name) {
           downloadKeys(name, this.id, this.key)
        }
    },
    components: {
        Navbar,
        Loader,
        Error,
        Info,
        Logo
    }
}
</script>

<style>

</style>