<template>
    <Logo color="purple" class="logo-fix" />
   <div class="row h-full">
        <div class="left" v-if="sidebar">
            <Info v-model="drawer" :file="info" v-if="drawer" />
            <div v-if="step <= 1">
                <img src="/images/undraw_security_o890.svg" class="art">
                <h1 class="title">{{ $t('title.is_secure') }}</h1>
                <ul>
                    <li>{{ $t('desc.generate_rsa') }}</li>
                    <li>{{ $t('desc.hash_pass') }} (<a @click="openInfo('sha')">{{ $t('desc.prove_it') }}</a>)</li>
                    <li>{{ $t('desc.crypt_private_key') }} (<a @click="openInfo('aes')">{{ $t('desc.prove_it') }}</a>)</li>
                    <li>{{ $t('desc.not_external_link') }} (<a @click="openInfo('external-script')">{{ $t('desc.prove_it') }}</a>)</li>
                </ul>
            </div>
            <div v-else>
                <img src="/images/undraw_Security_on_ff2u.svg" class="art">
                <h1 class="title">{{ $t('title.key_public_created') }}</h1>
                <ul>
                    <li>{{ $t('desc.copy_link') }}</li>
                    <li>{{ $t('desc.user_crypt') }}</li>
                </ul>
            </div>
            <div class="center mt-10 mobile">
                <button class="btn" @click="sidebar = false">{{ $t('close') }}</button>
            </div>
        </div>
        <div class="right">
            <div v-if="step == 0" class="right-content">
                <form @submit.prevent="generate">
                        <h1 class="title mobile">{{ $t('title.create_keys') }} <a class="min" @click="sidebar = true">({{ $t('title.is_secure') }})</a></h1>
                        <div>
                            <input type="text" v-model="id" :placeholder="$t('desc.name_etc')" class="input" @focus="focusId = true" @blur="blurId">
                            <div v-if="focusId">
                                <p>{{ $t('desc.set_name') }}</p>
                                <p v-if="id">{{ $t('desc.uniq_link') }} : {{ newUrl }}</p>
                            </div>
                            <div v-if="existsId">
                                <p><i :class="checkIcon(false)" class="mr-10"></i>{{ $t('error.exist_id') }}</p>
                            </div>
                        </div>
                    <div>
                        <input type="password" v-model="password" :placeholder="$t('desc.set_pass')" class="input mt-10">
                        <div v-if="hasPassword">
                            <h4>{{ $t('title.min_requirement') }}</h4>
                            <ul class="pass-required"> 
                                <li><i :class="checkIcon(strength.year > MIN_YEAR)" class="mr-10"></i>{{ $t('desc.crack_pass', { time: crackTime }) }}</li>
                                <li><i :class="checkIcon(strength.num)" class="mr-10"></i>{{ $t('desc.min_num') }}</li>
                                <li><i :class="checkIcon(strength.lower)" class="mr-10"></i>{{ $t('desc.min_upper') }}</li>
                                <li><i :class="checkIcon(strength.upper)" class="mr-10"></i>{{ $t('desc.min_lower') }}</li>
                                <li><i :class="checkIcon(strength.notId)" class="mr-10"></i>{{ $t('desc.not_like_name') }}</li>
                                <li>- {{ $t('desc.uniq_pass') }}</li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="hasPassword">
                        <input type="password" v-model="confirmPassword" :placeholder="$t('desc.set_confirm_pass')" class="input mt-10">
                        <div v-if="confirmPassword && confirmPassword != password" class="mt-10">
                            <p><i :class="checkIcon(false)" class="mr-10"></i>{{ $t('error.pass_confirm') }}</p>
                        </div>
                    </div>
                    <button class="btn mt-10" :disabled="!isOk">
                       {{ $t('button.generate_key') }}
                    </button>
                </form>
            </div>
            <div v-else-if="step == 1">
                <div class="center">
                    <Loader class="mr-10" />
                    <p>{{ $t('loading.generate_key') }}</p>
                </div>
            </div>
             <div v-else>
                <input type="text" v-model="slugUrl" readonly id="crypt" class="input">
                <button class="btn btn-clip mt-10 mr-10" data-clipboard-target="#crypt">
                    {{ $t('copy') }}
                </button>
            </div>
        </div>
  </div>
</template>

<script>
import { generate } from '../workers/generate.worker'
import zxcvbn from 'zxcvbn'
import slugify from 'slugify'
import Loader from '../components/Loader'
import Logo from '../components/Logo'
import ClipboardJS from 'clipboard'
import axios from 'axios'
import { API_URL } from '../config'
import Info from '../components/Info'
import InfoMixin from '../mixins/info'

const MIN_YEAR = 10

export default {
    mixins: [InfoMixin],
    data() {
        return {
            step: 0,
            id: '',
            password: '',
            confirmPassword: '',
            focusId: false,
            slug: '',
            existsId: false,
            MIN_YEAR
        }
    },
    methods: {
        async generate() {
            this.step++
            const val = await generate(this.password, this.slugify)
            const { data: register } = await axios.post(API_URL + '/registerKeys', {
                id: this.id,
                ...val
            })
            this.slug = register.slug
            this.step++
            new ClipboardJS('.btn-clip')
        },
        async blurId() {
            this.focusId = false
            const { data: existsId } = await axios.post(API_URL + '/existId', {
                name: this.slugify
            })
            this.existsId = existsId
        },
        checkIcon(bool) {
            const val = !bool ? 'error' : 'success'
            return {
                ['el-icon-' + val]: true,
                [val]: true
            }
        }
    },
    computed: {
        slugUrl() {
            return `${API_URL}/encrypt/${this.slug}`
        },
        slugify() {
            return slugify(this.id).toLowerCase()
        },
        newUrl() {
            return `${API_URL}/encrypt/${this.slugify}`
        },
        hasPassword() {
            return this.password.length > 0
        },
        strength() {
            const strength = zxcvbn(this.password)
            const second = strength.crack_times_seconds.offline_slow_hashing_1e4_per_second
            const minute = second / 60
            const hour = Math.floor(minute / 60)
            const day = Math.floor(hour / 24)
            const year = Math.floor(day / 365.15)
            return {
                second,
                minute,
                hour,
                day,
                year,
                num: /[0-9]/.test(this.password),
                lower: /[a-z]/.test(this.password),
                upper: /[A-Z]/.test(this.password),
                notId: this.password != this.id
            }
        },
        isOk() {
            const { year, num, lower, upper, notId } = this.strength
            return year > MIN_YEAR && num && lower && upper && notId && this.password == this.confirmPassword
        },
        crackTime() {
            const { second, minute, hour, day, year } = this.strength
            if (second < 1) {
                return this.$t('time.less_sec')
            }
            else if (minute < 1) {
                return this.$t('time.less_min')
            }
            else if (hour < 1) {
                return this.$t('time.less_hour')
            }
            else if (hour < 24) {
                return this.$t('time.x_hour', { time: hour })
            }
            else if (day < 365) {
                return this.$t('time.x_day', { time: day })
            }
            else if (year > 1000 && year < 10000) {
                return this.$t('time.more_1e3')
            }
            else if (year > 10000 && year < 1e6) {
                return this.$t('time.more_1e6')
            }
            else if (year > 1e6 && year < 4.55e9) {
                return this.$t('time.more_1e9')
            }
            else if (year > 4.55e9 && year < 13.8e9) {
                return this.$t('time.more_earth')
            }
            else if (year > 13.8e9 && year < 1e100) {
                return this.$t('time.more_space')
            }
            else if (year > 1e100 && year < 1e150) {
                return this.$t('time.more_atom')
            }
            else if (year > 1e150) {
                return this.$t('time.infinite')
            }
            return this.$t('time.x_year', { time: year })
        },
       
    },
    components: {
        Loader,
        Info,
        Logo
    }
}
</script>

<style scoped>
.pass-required {
    list-style: none;
    padding-left: 10px;
}
</style>