import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ElementPlus from 'element-plus'
import  { createI18n } from 'vue-i18n'
import 'element-plus/lib/theme-chalk/index.css'
import messages from './languages'

library.add(faCheckCircle, faTimesCircle)

const i18n = createI18n({
    locale: 'fr',
    messages
})

createApp(App)
    .use(router)
    .use(ElementPlus)
    .use(i18n)
    .component('fa', FontAwesomeIcon)
    .mount('#app')
