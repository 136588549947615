<template>
   <div>

       <nav class="navbar navbar-expand-lg">
           <a class="navbar-brand"><Logo /></a>
       </nav>

       <div class="main-banner">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-6 col-md-12 center">
                        <div>
                            <h1>Send data securely and confidentially</h1>
                            <router-link :to="{ name: 'create' }" class="btn" tag="button">Generate keys</router-link>
                        </div>
                    </div>
                    <div class="mt-50 col-12 col-lg-6 offset-lg-0 col-md-10 offset-md-1 col-sm-12 ">
                        <img src="/images/banner1.png" class="img-responsive">
                    </div>
                </div>
            
            </div>
        </div>

        <section class="section-padding relative">
            <div class="container">
                <div class="section-heading text-center">
                    <h2>Why is it secure?</h2>
                </div>
                <div class="row" id="service">

                        <div  class="col-12 col-md-6 col-lg-4 item">
                             Transparent and RGPD eligible (servers located in Paris or Amsterdam)
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 item">
                            Encryption and decryption is done with the RSA-2048 algorithm. The private key is encrypted with AES-256
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 item">
                            Encryption and decryption is done in the browser. No data is sent or stored on the server
                        </div>
                         <div class="col-12 col-md-6 col-lg-4 item">
                         No tracking with cookies and so on
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 item">
                        We require a strong password
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 item">
                        The application does not contain external scripts like Google Analytics, which could compromise privacy
                        </div>
                </div>
            </div>
        </section>

        <footer class="footer-content-area mt-0">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-4 col-md-6">
                        <div>
                          {{ year }} Cryptery
                        </div>
                    </div>
                </div>
            </div>
        </footer>
   </div>
</template>

<script>
import Logo from '../components/Logo'

export default {
    components: {
        Logo
    },
    computed: {
        year() {
            return new Date().getFullYear()
        }
    }
}
</script>

<style scoped>
.left {
    max-width: 50%;
}

.main-banner {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    background-image: url(../assets/header-bg1.png);
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
    min-height: 750px;
}

.page {
    width: 900px;
    margin: auto;
}

.main-banner h1 {
    font-weight: 300;
    font-size: 40px;
}

.section-padding {
    padding-top: 100px;
    padding-bottom: 70px;
}

.section-heading {
    position: relative;
    z-index: 1;
    margin-bottom: 80px;
}

.text-center {
    text-align: center!important;
}

#service .item {
    border-radius: 10px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: 0 5px 40px 0 rgba(0,0,0,.11);
    border: none;
    padding: 40px 20px;
    margin-bottom: 30px !important;
}

.footer-content-area {
    padding: 80px 0;
    background: #192057 !important;
    color: white;
}

.navbar {
    background: #231279;
}
</style>