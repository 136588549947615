<template>
  <router-view></router-view>
</template>

<script>
import '../node_modules/bootstrap/dist/css/bootstrap-grid.css'
import './index.css'

export default {
  name: 'App'
}
</script>