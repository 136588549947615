<template>
  <div class="navbar">
    <span class="item">
      <el-tooltip effect="dark" :content="$t('help.public_key')" placement="bottom">
        <router-link :to="{ name: 'create' }" class="btn-white">{{ $t('button.create_public_key') }}</router-link>
      </el-tooltip>
    </span>
    <span class="item">
        <el-dropdown trigger="click" @command="$emit('command', $event)">
          <span class="el-dropdown-link">
             <el-tooltip effect="dark" :content="$t('settings')" placement="bottom">
              <i class="el-icon-setting btn-white"></i>
            </el-tooltip>
          </span>
          <template #dropdown>
            <slot></slot>
          </template>
        </el-dropdown>
    </span>
  </div>
</template>

<script>
export default {
   
}
</script>

<style scoped>
.navbar {
    position: absolute;
    top: 0;
    right: 0;
    height: 80px;
    display: flex;
    align-content: center;
    justify-content: center;
}

.navbar a {
  display: inline-block;
  text-decoration: none;
}

.navbar .item {
  font-size: 14px;
  line-height:18px;
  font-weight: 600;
  color: rgb(102,102,102);
  fill: rgb(102,102,102);
  cursor: pointer;
  user-select: none;
  align-items: center;
  border-radius: 6px;
  text-decoration: none;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-right: 15px;
}

.navbar .item i {
  font-size: 20px;
}
</style>