export function downloadKeys(name, id, key) {
    function downloadFile(data, name = 'file', type = 'text/plain') {
        const anchor = document.createElement('a')
        anchor.href = window.URL.createObjectURL(new Blob([data], { type }))
        anchor.download = name
        anchor.click()
    }
    switch (name) {
        case 'pubkey':
            var publicKey = key.exportKey('public')
            downloadFile(publicKey, `${id}.pub`)
            break;
        case 'pubkey-ssh':
            var sshKey = key.exportKey('openssh-public')
            downloadFile(sshKey + ' ' + id, `${id}-ssh.pub`)
            break;
        case 'privkey':
            var privateKey = key.exportKey('private.pem')
            downloadFile(privateKey, `${id}`)
            break;
        case 'privkey-ssh':
            var sshPrivKey = key.exportKey('openssh-private')
            downloadFile(sshPrivKey, `${id}-ssh`)
            break;
    }
}